
import {Component} from 'vue-property-decorator';
import {mdiAccountClock} from '@mdi/js';
import WidgetSettings from '@/modules/widgets/components/widget-settings';
import {statusesModule} from '@/modules/tickets/shared';
import {SelectListItem} from '@/shared/models/select-list-item';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';

@Component({
    props: {
        settings: Array,
    },

    components: {
        IconChooser
    },
})
export default class TicketWithStatusWidgetSettings extends WidgetSettings {
    public statusesTypes = statusesModule.actionsTypes;
    public iconsStatus = [
        {
            id: 1,
            slug: 'mdi-new-box',
        },
        {
            id: 2,
            slug: 'mdi-note',
        },
        {
            id: 3,
            slug: 'mdi-check',
        },
        {
            id: 4,
            slug: 'mdi-file',
        },
        {
            id: 5,
            slug: 'mdi-flash',
        },
        {
            id: 6,
            slug: 'mdi-account',
        },
        {
            id: 7,
            slug: 'mdi-message',
        },
        {
            id: 8,
            slug: 'mdi-format-list-bulleted',
        },
    ];

    public icons: object = {
        mdiAccountClock,
    };

    get statuses() {
        return this.$store.state.ticketsState.statusesState.dataList.map((item: SelectListItem) => {
            return {
                ...item,
                id: String(item.id),
            };
        });
    }

    public mounted() {
        if (this.statuses && this.statuses.length < 1) {
            this.$store.dispatch(this.statusesTypes.FETCH_DATA, {simple: true});
        }
    }
}
