
  import {Component} from 'vue-property-decorator';
  import WidgetComponent from '../widget-component';
  import {AccountLimitWidgetData} from '@/modules/widgets/shared/models/types/acount-limits-widget';

  @Component
  export default class AccountLimitWidget extends WidgetComponent<AccountLimitWidgetData> {
    public currentSize!: number;
    public maxSize!: number;
    public isMApp = (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs');
    public tab: any = null;

    get maxUsers() {
      const maxUsers = this.data.max_users;
      if (maxUsers === 0) {
        return 'ꝏ';
      } else {
        return maxUsers;
      }
    }

    get maxInvestments() {
        const maxInvestmets = this.data.max_investments;
        if (maxInvestmets === 0) {
            return 'ꝏ';
        } else {
            return maxInvestmets;
        }
    }

    get activeUsers() {
      return this.data && this.data.active_users
        ? Number(this.data.active_users)
        : '-';
    }

    get percentageOfUsedLimitUsers() {
      const maxUsers = Number(this.maxUsers);
      const activeUsers = Number(this.activeUsers);
      if (!isNaN(maxUsers) && !isNaN(activeUsers)) {
        const ret = (activeUsers / maxUsers) * 100;
        return ret.toFixed(0) + '%';
      }
      return 'ꝏ';
    }

    get activeInvestments() {
      return this.data && this.data.investments_count
        ? Number(this.data.investments_count)
        : '-';
    }

    get percentageOfUsedLimitInvestments() {
      const maxInvestmets = Number(this.maxInvestments);
      const activeInvestments = Number(this.activeInvestments);
      if (!isNaN(maxInvestmets) && !isNaN(activeInvestments)) {
        const ret = (activeInvestments / maxInvestmets) * 100;
        return ret.toFixed(0) + '%';
      }
      return '';
    }

    get percentageOfUsedLimitMemory() {
      const maxSize = Number(this.data.max_media_size_bytes);
      const currentSize = Number(this.data.used_media_size_bytes);
      if (!isNaN(maxSize) && !isNaN(currentSize)) {
        const value = (currentSize / maxSize) * 100;
        const x = Math.round(value);
        return x + '%';
      }
      return '';
    }

    public created() {
        this.currentSize = this.data.used_media_size_bytes * Math.pow(10, -9);
        this.maxSize = this.data.max_media_size_bytes * Math.pow(10, -9);
    }

    public days_between(date: any) {
        const today: any = new Date();
        const endDate: any = new Date(date);
        const diffTime = Math.abs(endDate - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    }

    public byteToGigaByte(n: any) {
        return  (n / Math.pow(10, 9));
    }

  }
