
  import {Component, Prop} from 'vue-property-decorator';
  import WidgetComponent from '../widget-component';
  import {UnsignedIssuesWidgetData} from '@/modules/widgets/shared/models/types/unsigned-issues-widget';
  import { mdiAccountClock } from '@mdi/js';


  @Component
  export default class UnsignedIssuesWidget extends WidgetComponent<UnsignedIssuesWidgetData> {
    @Prop(Boolean) public disabled!: boolean;
    public icons: object = {
      mdiAccountClock,
    };

    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    get color() {
      const num = this.data.number_of_unsigned_issues;
      switch (true) {
        case (num === 0):
          return '';
        case ( num <= 5 && num !== 0):
          return 'green';
        case ( num <= 10 && num > 5):
          return 'yellow';
        case ( num <= 15 && num > 10):
          return 'red';
      }
    }
  }
