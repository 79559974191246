import { render, staticRenderFns } from "./new-notifications.vue?vue&type=template&id=91ce888a&scoped=true&"
import script from "./new-notifications.vue?vue&type=script&lang=ts&"
export * from "./new-notifications.vue?vue&type=script&lang=ts&"
import style0 from "./new-notifications.vue?vue&type=style&index=0&id=91ce888a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ce888a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VDivider,VExpansionPanel,VExpansionPanelHeader,VExpansionPanels,VList,VSkeletonLoader,VSlideXTransition})
