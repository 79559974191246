import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetSetting } from '@/modules/widgets/shared/models/widget-setting';
import { contrast, hexToRgb } from '@/shared/helpers/colors';
import { parseDate, parseDateTime, parsePrice } from '@/shared/helpers';

@Component
export default class WidgetComponent<T> extends Vue {
    @Prop(Object) public data!: T;
    @Prop(String) public title!: string;
    @Prop(Array) public settings!: WidgetSetting[];

    /* Additional Methods */
    public parseDate = parseDate;
    public parseDateTime = parseDateTime;
    public parsePrice = parsePrice;

    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');

    get events() {
        const events: any = {
            contextmenu: (e: MouseEvent) => {
                e.preventDefault();
                this.$emit('contextmenu', e);
            },
        };
        if (this.$listeners.click) {
            events.click = (e: MouseEvent) => this.$emit('click', e);
        }
        return events;
    }

    get componentSettings(): { [k: string]: string } {
        const settings: { [k: string]: string } = {};
        this.settings.forEach(({ key, value }) => {
            settings[key] = value;
        });
        return settings;
    }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }
}
