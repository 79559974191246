import {Component, Vue, Watch} from 'vue-property-decorator';
import {logger} from '@/shared/services';
import {mdiChevronLeft, mdiChevronRight} from '@mdi/js';
import {FetchParams, FooterProps, MetaItem} from '@/shared/types';
import {actionsTypes as authTypes} from '@/modules/auth/shared/state';
import {actionsTypes as settingsActions} from '@/modules/settings/shared/state';
import {parseDate, parseDateTime, parsePrice} from '@/shared/helpers';

@Component
export default class ListComponent<T> extends Vue {
    public headers!: object[]; // Abstract
    public store!: any; // Abstract
    public fetchAction!: string; // Abstract
    public removeAction!: string; // Abstract
    public restoreAction: string = '';
    public removeItemsAction: string = '';
    public parseDate = parseDate;
    public parseDateTime = parseDateTime;
    public parsePrice = parsePrice;

    public main: boolean = true;
    public destroyObject: boolean = false;
    public id?: number = 0;
    public rows: number = 0;
    public page: number = 1;
    public search: string = '';
    public editedItem: any = {} as T;
    public formDialog: boolean = false;
    public showDialog: boolean = false;
    public removeDialog: boolean = false;
    public restoreDialog: boolean = false;
    public itemsCount: number[] = [10, 25, 50];
    public fetchParams: FetchParams = {} as FetchParams;
    public selected: T[] = [];
    public select: boolean = false;

    public footer: FooterProps = {
        nextIcon: mdiChevronRight,
        prevIcon: mdiChevronLeft,
        disableItemsPerPage: true,
        disablePagination: true,
        itemsPerPageOptions: this.itemsCount,
    };

    private timer: any = 0;
    private typingDelayTime: number = 600;

    public  created() {
        //
    }

    get loading() {
        return this.store.loading;
    }

    get removing() {
        return this.store.removing;
    }

    get restoring() {
        return this.store.restoring;
    }

    get error() {
        return this.store.listError;
    }

    get footerProps() {
        this.footer.disableItemsPerPage = this.paginate || this.loading;
        this.footer.disablePagination = this.paginate || this.loading;
        return this.footer;
    }

    get errorMessages() {
        return this.store.listErrorMessages;
    }

    get paginate() {
        return this.$store.state.settingsState.loading;
    }

    get edit() {
        return this.editedItem && Number(this.editedItem.id) > 0;
    }

    get per_page() {
        return this.store.meta ? this.store.meta.per_page : 10;
    }

    set per_page(rowCount: number) {
        if (rowCount !== this.per_page) {
            this.$store.dispatch(settingsActions.UPDATE_SETTING, {key: this.meta.pagination_key, value: rowCount})
                .then(() => {
                    this.page = 1;
                    this.fetchData();
                });
        }
    }

    get current_page() {
        return this.store.meta ? this.store.meta.current_page : 1;
    }

    get last_page() {
        return this.store.meta ? this.store.meta.last_page : 0;
    }

    get total() {
        return this.store.meta ? this.store.meta.total : 0;
    }

    get meta() {
        return this.store.meta as MetaItem;
    }

    @Watch('page')
    public changePage() {
        clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            this.fetchData();
        }, this.typingDelayTime);
    }

    @Watch('formDialog')
    public OnDialogChange(value: boolean) {
        if (!value) {
            this.closeForm();
        }
    }

    @Watch('search')
    public OnSearchChange() {
        clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            this.page = 1;
            this.fetchData();
        }, this.typingDelayTime);
    }

    public showItem(item: T | any) {
        if (this.main) {
            this.editedItem = Object.assign({}, item);
            this.showDialog = true;
        } else {
            this.$emit('itemShowed', String(item.id));
        }
    }

    public goToItem(routeName: string, routeParams: any) {
        this.$router.push({name: routeName, params: routeParams})
            .catch((err: any) => logger.error(err));
    }

    public createItem() {
        this.setDefaultData();
        this.formDialog = true;
    }

    public editItem(item: T) {
        this.editedItem = Object.assign({}, item);
        this.formDialog = true;
    }

    public deleteItem(item: T) {
        this.editedItem = Object.assign({}, item);
        this.removeDialog = true;
    }

    public restoreItem(item: T) {
        this.editedItem = Object.assign({}, item);
        this.restoreDialog = true;
    }

    public itemSaved() {
        this.formDialog = false;

        if (!this.edit && !this.search) {
            const futurePage = Math.round((this.total + 1) / this.per_page);
            this.page = (futurePage > this.last_page) ? this.last_page + 1 : this.last_page;
        }

        if (this.main) {
            this.setDefaultData();
            this.fetchData();
        } else {
            this.$emit('itemChanged');
        }
    }

    public closeForm() {
        this.formDialog = false;

        setTimeout(() => {
            this.setDefaultData();
        }, 200);
    }

    public closeRemove() {
        this.removeDialog = false;
        this.setDefaultData();
    }

    public setDefaultData() {
        this.editedItem = Object.assign({}, {} as T);
    }

    public fetchData() {
        if (this.fetchAction) {
            const params = this.parseParams(this.fetchParams ? this.fetchParams : {} as FetchParams);

            this.$store.dispatch(this.fetchAction, params)
                .catch((error) => {
                    logger.error(error);
                }).then((response) => {
                    if (!response) {
                        this.$store.dispatch(authTypes.CHECK_USER).catch((err) => {
                            logger.error(err);
                        }).then((res) => {
                            if (!res) {
                                this.$router.push('/auth/login').catch((err) => logger.error(err));
                            }
                        });
                    }
                });
        }
    }

    public removeItemRequest() {
        if (this.removeAction) {
            this.$store.dispatch(this.removeAction, this.destroyObject ? this.editedItem : Number(this.editedItem.id))
                .then((response) => {
                    if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                        this.removeDialog = false;

                        if (this.meta && (this.meta.to - this.meta.from) < 1 && this.page === this.last_page) {
                            this.page--;
                        }

                        this.fetchData();
                    }
                })
                .catch((err) => logger.error(err));
        }
    }

    get selectedIds(): number[] {
        return this.selected.length > 0 ? this.selected.map((e: any) => e.id) : [];
    }

    public removeItemsRequest() {
        if (this.removeItemsAction) {
            this.$store.dispatch(this.removeItemsAction, this.selectedIds)
                .then((response) => {
                    if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                        this.removeDialog = false;
                        this.select = false;
                        this.selected = [];

                        if (this.meta && (this.meta.to - this.meta.from) < 1 && this.page === this.last_page) {
                            this.page--;
                        }

                        this.fetchData();
                    }
                })
                .catch((err) => logger.error(err));
        }
    }

    public closeRemoveDialog() {
        this.removeDialog = false;
        this.select = false;
    }

    public restoreItemRequest() {
        if (this.restoreAction) {
            this.$store.dispatch(this.restoreAction, Number(this.editedItem.id))
                .then((response) => {
                    if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                        this.restoreDialog = false;
                        this.fetchData();
                    }
                })
                .catch((err) => logger.error(err));
        }
    }

    public assignFilters(filters: object) {
        if (!this.fetchParams.filters) {
            this.fetchParams.filters = {};
        }

        this.fetchParams.filters = Object.assign(this.fetchParams.filters, filters);
        this.fetchData();
    }

    private parseParams(params: FetchParams) {
        params.page = this.page;

        if (!params.filters) {
            params.filters = {};
        }

        if (this.search) {
            params.filters = Object.assign(params.filters, {search: this.search});
        } else {
            // @ts-ignore
            delete params.filters.search;
        }

        if (this.id !== 0) {
            params.id = this.id;
        }

        return params;
    }
}
