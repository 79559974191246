
import {Component} from 'vue-property-decorator';
import WidgetComponent from '../widget-component';
import {TicketWithStatusWidgetData} from '@/modules/widgets/shared/models/types/ticket-with-status-widget';
import {mdiAccountClock} from '@mdi/js';

@Component
export default class TicketWithStatusWidget extends WidgetComponent<TicketWithStatusWidgetData> {
    public icons: object = {
        mdiAccountClock,
    };
    public isMApp = (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs');

    get status() {
        return this.data.statuses ?
            this.data.statuses.find((status) => Number(this.componentSettings.status) === status.id) :
            {};
    }
}
