
    import { Component } from 'vue-property-decorator';
    import WidgetComponent from '../widget-component';
    import {
        TicketsForTodayWidgetData,
    } from '@/modules/widgets/shared/models/types/tickets-for-today-widget';
    import { mdiPencil, mdiAccount, mdiAlert, mdiCheckBold } from '@mdi/js';
    import Item from '@/modules/tickets/components/list/item.vue';

    @Component({
        components: {
            Item,
        },
    })
    export default class TicketsForTodayWidget extends WidgetComponent<TicketsForTodayWidgetData> {
        public tickets: any = [];
        public icons: object = {
            mdiPencil,
            mdiAccount,
            mdiAlert,
            mdiCheckBold,
        };
        get ticketsForm() {
            if (this.tickets.length >= 5 || this.tickets.length <= 0) {
                return 'Zadań';
            }
            if (this.tickets.length > 1) {
                return 'Zadania';
            }
            if (this.tickets.length === 1) {
                return 'Zadanie';
            }
        }
        public mounted() {
            this.tickets = this.data.tickets;
        }
        public onRemove(id: number) {
            this.tickets = this.tickets.filter((el: any) => el.id !== id);
        }
    }
