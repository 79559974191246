
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { contrast, hexToRgb } from '@/shared/helpers/colors';
    import NameReduce from '@/shared/components/elements/name-reduce.vue';
    import { ticketsModule } from '@/modules/tickets/shared';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
    import UserPicker from '@/modules/tickets/components/pickers/user-picker.vue';
    import StatusPicker from '@/modules/tickets/components/pickers/status-picker.vue';
    import { Inject, Watch, Prop } from 'vue-property-decorator';
    import { TicketsState } from '../../shared/state/state';
    import { ticketConnectionTypes } from '@/shared/config/tickets';

    @Component({
        props: {
            title: String,
            description: String,
            priority: Object,
            created_at: String,
            id: Number,
            due_date: String,
            reporter: Object,
            assignable_type: String,
            assigned_user: Object,
            status: Object,
            onWidget: Boolean,
            category: Object,
        },
        components: {
            NameReduce,
            ActionConfirmDialog,
            UserPicker,
            StatusPicker,
        },
    })
    export default class Item extends Vue {
        @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

        @Prop(String) public title!: string;
        @Prop(Boolean) public noClickEvents!: boolean;
        @Prop(String) public description!: string;
        @Prop(Object) public priority!: any;
        // tslint:disable-next-line:variable-name
        @Prop(String) public created_at!: string;
        @Prop(Number) public id!: number;
        // tslint:disable-next-line:variable-name
        @Prop(String) public due_date!: string;
        @Prop(Object) public reporter!: any;
        // tslint:disable-next-line:variable-name
        @Prop(String) public assignable_type!: string;
        // tslint:disable-next-line:variable-name
        @Prop(Object) public assigned_user!: any;
        @Prop(Object) public status!: any;
        @Prop(Boolean) public onWidget!: boolean;
        @Prop(Object) public category!: any;

        get currentUser() {
            return this.$store.state.authState.user.id;
        }

        get isAnyTicketDragged() {
            return this.store.isAnyTicketDragged;
        }
        public store: TicketsState = this.$store.state.ticketsState;

        public actionsTypes = ticketsModule.actionsTypes;
        public mouseOver = false;
        public assignedUserLoading = false;
        public removeDialog = false;
        public assignedUser = {};

        private whiteColor = hexToRgb('#ffffff');
        private blackColor = hexToRgb('#000000');

        public mounted() {
            this.assignedUser = this.$props.assigned_user;
        }

        public connectionTypes(connectionTypes: string) {
            return ticketConnectionTypes.find((obj) => obj.id === connectionTypes);
        }

        public showRemoveDialog() {
            this.removeDialog = true;
        }
        public closeRemoveDialog() {
            this.removeDialog = false;
        }
        public deleteTicket() {
            this.$store.dispatch(this.actionsTypes.REMOVE_ITEM, this.$props.id);
            this.$emit('remove');
            this.removeDialog = false;
        }

        public goToDetails() {
            this.$router.push(`/tickets/${this.$props.id ? this.$props.id : ''}`);
        }

        public assignUser(e: any) {
            this.assignedUserLoading = true;
            e.then((res: any) => {
                this.assignedUser = res.data.data.assigned_user;
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: `Osoba została przypisana do zadania`,
                });
            })
                .catch((err: any) => {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                })
                .finally(() => {
                    this.assignedUserLoading = false;
                });
        }

        public removeAssignedUser() {
            this.assignedUserLoading = true;
            this.$store
                .dispatch(this.actionsTypes.UPDATE_ITEM, {
                    id: this.$props.id,
                    assigned_user_id: null,
                })
                .then((val) => {
                    this.assignedUser = val.data.assigned_user;
                    this.assignedUserLoading = false;
                });
        }

        public isDark(color: string) {
            return contrast(hexToRgb(color), this.whiteColor) > 2;
        }

        public isLight(color: string) {
            return contrast(hexToRgb(color), this.blackColor) > 2;
        }
    }
