import AccountLimitWidget from '@/modules/widgets/components/widgets/acount-limits.vue';
import UnsignedIssuesWidget from '@/modules/widgets/components/widgets/unsigned-issues.vue';
import TicketWithStatusWidget from '@/modules/widgets/components/widgets/ticket-with-status.vue';
import TicketWithStatusWidgetSettings from '@/modules/widgets/components/widget-settings/ticket-with-status.vue';
import TicketsForTodayWidget from '@/modules/widgets/components/widgets/tickets-for-today.vue';
import NewNotificationsWidget from '@/modules/widgets/components/widgets/new-notifications.vue';
import CalendarWidget from '@/modules/widgets/components/widgets/calendar.vue';


export const widgetTypes = [
    {name: 'account_limits', component: AccountLimitWidget},
    {name: 'unsigned_issues', component: UnsignedIssuesWidget},
    {name: 'ticket_with_status', component: TicketWithStatusWidget, settings: TicketWithStatusWidgetSettings},
    {name: 'tickets_for_today', component: TicketsForTodayWidget},
    {name: 'new_notifications', component: NewNotificationsWidget},
    {name: 'calendar', component: CalendarWidget},
];
