
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';
import WidgetComponent from '../widget-component';
import { mdiAccountClock } from '@mdi/js';
import { polishModuleNames } from '@/shared/config/notifications';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import { notificationsModule } from '@/modules/notifications/shared';
import moment from 'moment';
import { Notification } from '@/modules/notifications/shared';
import { httpClient } from '@/shared/services';
import ChangeDueDateDialog from '@/modules/notifications/components/dialogs/change-due-date-dialog.vue';
import { notificationsComponents } from '@/modules/notifications/shared/config/notifications';


@Component({
    props: {
        data: Object,
        settings: Array,
        title: String,
    },
    components: {
        InfiniteScroll,
        ...notificationsComponents,
    },
})
export default class NewNotificationsWidget extends WidgetComponent<any> {

    get titleText() {
        if (!this.data.notifications || (this.data.notifications.length > 1 && this.data.notifications.length < 5)) {
            return this.title;
        } else if (this.data.notifications.length === 0 || this.data.notifications.length >= 5) {
            return 'Nowych Powiadomień';
        } else {
            return 'Nowe Powiadomienie';
        }
    }

    get queue() {
        return this.$store.state.notificationsState.notificationsQueue;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }
    @Ref() public readonly list!: Vue;
    public icons: object = {
        mdiAccountClock,
    };
    public fetchAction = notificationsModule.actionsTypes.FETCH_EXTRA_NOTIFICATIONS;
    public showAction = notificationsModule.actionsTypes.SHOW_ITEM;
    public notifications: Notification[] = [];
    public total: number = 0;
    public moment = moment;
    public ordinalDate = moment();
    public showNewDateDialog = false;

    // todo: change type to string[]
    public brandNewNotificationIds: any[] = [];
    public selectedModule: string = 'all';
    public modules = [...polishModuleNames, { id: 'all', namePlural: 'Wszystkie' }];
    public dueDateDialogData = { initialDate: '', apiUrl: '' };


    public created() {
        this.notifications = this.data.notifications;
        this.total = this.data.total;
        if (this.notifications.length > 0) {
            this.ordinalDate = this.data.notifications[0].created_at;
        }
    }

    public addComment({ content, elementType, elementId }: any) {
        httpClient
            .post(`${this.getApiUrlByElement(elementType, elementId)}/comments`, { content, user: this.currentUser })
            .then((res) => {
                if (res.status === 201) {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Dodano komentarz' });
                } else {
                    throw new Error(res.statusText);
                }
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            });
    }

    public changeDueDate({ due_date, elementType, elementId }: any) {
        this.$set(this.dueDateDialogData, 'initalDate', this.parseDate(due_date));
        this.$set(this.dueDateDialogData, 'apiUrl', this.getApiUrlByElement(elementType, elementId));
        this.showNewDateDialog = true;
    }

      public getComponent(type: keyof typeof notificationsComponents) {
            return notificationsComponents[type];
        }

    public appendNotifications({ data }: any) {
        this.notifications.push(...data);
    }

    @Watch('queue', { deep: true })
    public onQueueChange(val: Notification[]) {
        if (val.length > 0) {
            const { id } = val.shift() as Notification;

            httpClient.get(`api/v1/notifications/${id}`).then(({ data }: any) => {
                this.notifications.unshift(data);
                this.total++;
                this.brandNewNotificationIds.push(id);
            });
        }
    }

    private getApiUrlByElement(elementType: string, elementId: number) {
        if (elementType === 'InvestmentSubject') {
            return `api/v1/investments/subjects/}`;
        }
        return `api/v1/${elementType.toLowerCase()}s/${elementId}`;
    }
}
