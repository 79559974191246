
import { Component } from 'vue-property-decorator';
import { widgetsModule } from '@/modules/widgets/shared/state/module';
import { widgetTypesModule } from '@/modules/widgets/shared/state/submodules';

import { Widget } from '@/modules/widgets/shared/models/widget';
import { ModuleProps } from '@/shared/state/template/module-props';
import draggable, { MoveEvent } from 'vuedraggable';
import { widgetTypes } from '@/shared/config/widgets';
import WidgetForm from '@/modules/widgets/components/form.vue';
import ListComponent from '@/shared/components/layout/list-component';
import { SaveResponse, Snackbar } from '@/shared/types';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

@Component({
    components: {
        draggable,
        WidgetForm,
        FormActions,
    },
})
export default class WidgetsList extends ListComponent<Widget> {
    public actionsTypes = widgetsModule.actionsTypes;
    public headers: object[] = [];
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public props: ModuleProps = widgetsModule.moduleProps;
    public mutationsTypes = widgetsModule.mutationsTypes;

    public mutationsTestTypes = widgetTypesModule.mutationsTypes;

    public store = this.$store.state.widgetsState;
    public validSettings = false;

    public idTable = 1;
    public hide = true;
    public isMounted = false;
    public drop = {};
    public isMApp = (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs');

    public hintPositions = [false, false, false];

    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
        forceFallback: true,
    };

    public list0 = [];

    public list1 = [];

    public list2 = [];

    public list3 = [];

    public arrangmentMode = false;

    get settingsFormDialog() {
        return this.$store.state.widgetsState.settingsFormDialog;
    }

    set settingsFormDialog(value) {
        this.$store.commit(this.mutationsTypes.UPDATE_SEETINGS_DIALOG, value);
    }

    public created() {
        //
    }

    public mounted() {
        this.$store.dispatch(this.actionsTypes.FETCH_DATA, { simple: true }).then(({ data }) => {
            if (data) {
                for (let index = 0; index <= 3; index++) {
                    const widgets = data.filter((widget: Widget) => widget.column === index);
                    this.$set(this.$data, `list${index}`, widgets);
                }
            }
            this.isMounted = true;
        });
    }

    public setDragCursor(value: any) {
        const html = document.getElementsByTagName('html').item(0) as HTMLElement;
        html.classList.toggle('grabbing', value);
    }

    public onDragStart() {
        this.setDragCursor(true);
        this.setHintPositions();
    }
    public onDragEnd() {
        this.setDragCursor(false);
        this.setHintPositions();
        this.checkDrop();
    }

    public getList(row: number) {
        return this[`list${row}` as keyof this];
    }

    public widgetType(type: string) {
        return widgetTypes.find((e: any) => e.name === type);
    }

    public openSettings(widget: Widget) {
        this.editedItem = JSON.parse(JSON.stringify(widget));
        this.settingsFormDialog = true;
    }

    public updateSettings() {
        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, this.editedItem).then((response) => {
            if (response.data) {
                const index = this.$data['list' + response.data.column].findIndex(
                    (obj: { id: number }) => obj.id === response.data.id,
                );
                this.$data['list' + response.data.column].splice(index, 1, response.data);
                this.$store.commit('SHOW_SNACKBAR', { text: 'Zaktualizowano widget', type: 'success' } as Snackbar);
                this.settingsFormDialog = false;
            }
        });
    }

    public mouseOn() {
        this.hide = !this.hide;
    }

    public checkMove(evt: MoveEvent<any>) {
        const column = evt.relatedContext.component.$el.id.substring(4, 5);
        const order = evt.draggedContext.futureIndex + 1;
        const id = evt.draggedContext.element.id;
        this.setHintPositions(evt.to, evt.from);
        this.drop = {
            column,
            order,
            id,
        };
    }

    public checkDrop() {
        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, this.drop);
    }

    public deleteWidget(id: number, column: number, type: number) {
        this.$store.dispatch(this.actionsTypes.REMOVE_ITEM, { id }).then((resp) => {
            if (resp) {
                const index = this.$data['list' + column].findIndex((obj: Widget) => obj.id === id);
                this.$delete(this.$data['list' + column], index);
                const data: Widget[] = this.list1.concat(this.list2, this.list3);
                const widgetsWithType = data.filter((widget) => widget.widget_type_id === type);
                if (widgetsWithType.length < 1) {
                    this.$store.commit(this.mutationsTestTypes.RESET_TYPE, type);
                }
            }
        });
    }

    public addWidget(object: [], column: number) {
        this.$data['list' + column].push(object);
    }

    public openDialog(column: number) {
        this.formDialog = true;
        this.editedItem = { column };
    }

    public itemClose() {
        this.formDialog = false;
    }

    public itemSave(response: SaveResponse<Widget>) {
        this.formDialog = false;
        const column = response.data.column;
        this.$data['list' + column].push(response.data);
    }

    private setHintPositions(to?: Element, from?: Element) {
        if (to && from) {
            const fromIndex = parseInt(from.id.at(-1) as string, 10);
            const toIndex = parseInt(to.id.at(-1) as string, 10);

            this.hintPositions = [
                (this.list0.length === 0 && toIndex !== 0) ||
                    (this.list0.length === 1 && fromIndex === 0 && fromIndex !== toIndex),
                (this.list1.length === 0 && toIndex !== 1) ||
                    (this.list1.length === 1 && fromIndex === 1 && fromIndex !== toIndex),
                (this.list2.length === 0 && toIndex !== 2) ||
                    (this.list2.length === 1 && fromIndex === 2 && fromIndex !== toIndex),
                (this.list3.length === 0 && toIndex !== 3) ||
                    (this.list3.length === 1 && fromIndex === 3 && fromIndex !== toIndex),
            ];
        } else {
            this.hintPositions = [this.list1.length === 0, this.list2.length === 0, this.list3.length === 0];
        }
    }
}
