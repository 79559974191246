

import {Component, Vue} from 'vue-property-decorator';
import Widgets from '@/modules/widgets/components/widgets.vue';

@Component({
    components: {
        Widgets,
    },
})

export default class HomeDashboard extends Vue {

}
