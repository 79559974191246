
import {Component} from 'vue-property-decorator';
import WidgetComponent from '../widget-component';
import plLocale from '@fullcalendar/core/locales/pl';
import {Calendar, CalendarData, CalendarOptions, EventClickArg} from '@fullcalendar/core';
import {viewTypes} from '@/shared/config/calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {CalendarWidgetData} from '@/modules/widgets/shared/models/types/calendar-widget';
import {calendarModule} from '@/modules/calendar/shared/state/module';
import {CalendarEvent} from '@/modules/calendar/shared/models/calendar-event';
import {DayHeaderContentArg, EventApi, EventInput} from '@fullcalendar/vue';
import EventDetails from '@/modules/calendar/components/details.vue';
import SystemBar from '@/shared/components/elements/system-bar.vue';


@Component({
    components: {
        EventDetails,
        SystemBar,
    },
})
export default class CalendarWidget extends WidgetComponent<CalendarWidgetData> {
    public mutationTypes = calendarModule.mutationsTypes;
    public actionsTypes = calendarModule.actionsTypes;
    public calendar: Calendar | null = null;
    public store = this.$store.state.calendarState;
    public calendarEvents: CalendarEvent[] = [];
    public viewTypes = viewTypes;
    public x: number = 0;
    public y: number = 0;
    public currentEvent: EventApi | null = null;
    public showDialog = false;
    public main: boolean = true;
    public showId: number = 0;
    public destroyObject: boolean = false;
    public id?: number = 0;
    public mobileMenu = false;
    public isMApp = this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';


    get calendarOptions(): CalendarOptions {
        return {
            plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, bootstrapPlugin],
            initialView: 'dayGridMonth',
            locales: [plLocale],
            locale: 'pl',
            nowIndicator: true,
            timeZone: 'local',
            editable: true,
            dayMaxEvents: true,
            height: 425,
            eventStartEditable: false,
            snapDuration: {milliseconds: 900000},
            nextDayThreshold: '00:00:00',
            firstDay: 1,
            headerToolbar: {
                left: '',
                center: '',
                right: ''
            },
            businessHours: {
                startTime: '8:00', // a start time (10am in this example)
                endTime: '16:00', // an end time (6pm in this example)
            },

            views: {
                day: {
                    viewClassNames: 'day-view',
                },
                week: {
                    viewClassNames: 'week-view',
                },
                dayHeaderFormat: {weekday: 'short', day: 'numeric', omitCommas: true},
                timeGrid: {
                    dayHeaderFormat: {weekday: 'short', day: 'numeric', omitCommas: true},
                    slotLabelFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short'
                    },
                    allDayText: '',
                    allDayClassNames: 'all-day-header',
                    dayHeaderContent: (arg: DayHeaderContentArg) => {
                        const dayText = arg.text.split(' ');
                        return {html: `<span>${dayText[0]}</span><div class="day-number">${dayText[1]}</div>`};
                    },
                },
                dayGrid: {
                    dayHeaderContent: (arg: DayHeaderContentArg) => {
                        return {html: arg.text.slice(0, -1)};
                    }
                },
                list: {
                    listDayFormat: {weekday: 'long', day: 'numeric', omitCommas: true},
                    dayHeaderContent: (arg: DayHeaderContentArg) => {
                        const dayText = arg.text.split(' ');
                        return {html: `<span>${dayText[0]}</span><h1>${dayText[1]}</h1>`};
                    },
                }
            },

            eventClick: this.showEvent,
            eventClassNames: ({event}) => {
                return ['calendar-event', ('event-' + event.id)];
            },

            eventSources: [
                {
                    color: 'primary',
                    id: 'current-user',
                    events: (fetchInfo, successCallback, errorCallback) => {
                        this.$store.dispatch(this.actionsTypes.FETCH_DATA, {
                            simple: false,
                            filters: {
                                start_date: this.parseDateTime(fetchInfo.start),
                                end_date: this.parseDateTime(fetchInfo.end)
                            }
                        })
                            .then((response) => {
                                if (response.data) {
                                    const events = this.items.map((event: CalendarEvent) => this.parseEvent(event));
                                    successCallback(events);
                                }
                            })
                            .catch(errorCallback);
                    },
                },
                {
                    color: '#777',
                    className: 'edited-event',
                    id: 'edited-event',
                    events: [],
                }
            ],
        };
    }

    get current() {
        return this.store.current;
    }

    set current(value) {
        this.$store.commit(this.mutationTypes.UPDATE_CURRENT, value);
    }

    get items(): [] {
        return this.store.data;
    }

    get currentData(): CalendarData | null {
        return this.calendar ? this.calendar.getCurrentData() : null;
    }

    get loading() {
        return this.store.loading;
    }

    public mounted() {
        const calendarEl = document.getElementById('calendar');
        if (calendarEl) {
            this.calendar = new Calendar(calendarEl, this.calendarOptions);
            this.calendar.render();
        }
    }

    public parseEvent(event: CalendarEvent): EventInput {
        return {
            id: event.id ? String(event.id) : 'new',
            title: event.name || '',
            start: event.start_date,
            end: event.end_date,
            allDay: event.all_day,
        };
    }

    public showEvent({event, el}: EventClickArg) {
        if (event.source && event.source.id !== 'edited-event') {
            this.currentEvent = event;
            this.setFormView(el);
            const clickedEvent = this.items.find((e: any) => e.id === Number(event.id));
            if (clickedEvent) {
                this.current = Object.assign({}, clickedEvent);
                this.showDialog = true;
            }
        }
    }

    public setFormView(el: Element, jsEvent?: MouseEvent) {
        const clientReact = el.getBoundingClientRect();
        const cardX = clientReact.x + clientReact.width + 400;

        if (this.calendar) {
            const view = this.calendar.view;
            this.y = clientReact.y;

            switch (view.type) {
                default:
                    if (cardX < window.innerWidth) {
                        this.x = clientReact.x + clientReact.width;
                    } else {
                        this.x = clientReact.x - 400;
                    }
                    break;
                case 'timeGridDay':
                    this.x = (clientReact.x + clientReact.width) / 2;
                    if (jsEvent) {
                        this.y = jsEvent.y;
                    }
                    break;
            }
        }
    }
}
