
import {Component} from 'vue-property-decorator';
import {widgetTypesModule} from '@/modules/widgets/shared/state/submodules';
import {widgetsModule} from '@/modules/widgets/shared/state/module';
import {WidgetDataRequest} from '@/modules/widgets/shared/requests/widget-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {widgetTypes} from '@/shared/config/widgets';
import {statusesModule} from '@/modules/tickets/shared';
import store from '@/store';
import { callSuper } from '@/shared/helpers';


@Component({
    components: {FormActions},
})

export default class WidgetForm extends FormComponent<WidgetDataRequest> {
    public actionsTypes = widgetsModule.actionsTypes;
    public widgetTypesActions = widgetTypesModule.actionsTypes;
    public store = this.$store.state.widgetsState;
    public updateAction = '';
    public storeAction = this.actionsTypes.STORE_ITEM;
    public requestData = new WidgetDataRequest();

    public statusesTypes = statusesModule.actionsTypes;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public created() {
        this.$store.dispatch(this.widgetTypesActions.FETCH_DATA, {simple: false});
    }

    get formTitle(): string {
        return this.edit ? '' : 'Dodaj widget';
    }

    public initForm() {
        this.form = this.$refs.createWidgetsForm;
         }

    get widgetsList() {
        return this.$store.state.widgetsState.widgetTypesState.data;
    }

    public getWidgetType(type: string) {
        return widgetTypes.find((e: any) => e.name === type);
    }

    get loadingWidgets() {
        return this.$store.state.widgetsState.widgetTypesState.loading;
    }
}
